.track-view {
  width: 100%;
}
.track-header {
  width: 100%;
}
.track-header h1 {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
}
.boarding {
  width: 100%;
  margin: 10px 0 10px 0;
}
.address {
  width: 100%;
  display: flex;
}
.address :nth-child(1) {
  width: 20%;
  padding: 10px 8px;
  margin: 2px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.address :nth-child(2) {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 8px;
  text-align: left;
}
.map-wrap {
  width: 100%;
  /* padding: 10px 0; */
  height: 500px;
  background-color: gray;
  position: relative;
}
.estimation-time {
  padding: 7px 0;
  background-color: rgba(0, 0, 255, 0.5);
  color: rgba(255, 255, 255, 0.8);
  font-size: small;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.conclude {
  width: 100%;
  box-shadow: -1px 0px 9px -3px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.conclude-wrap {
  width: 96%;
  box-shadow: -1px 0px 5px -3px #000000;
  margin: 0;
  position: relative;
}
.conclude-parts {
  width: 100%;
  height: 40px;
  display: flex;
  /* background-color: blue; */
  flex-grow: 0;
  flex-shrink: 0;
  margin: 5px 0;
}
.img {
  width: 20%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.conclude-content {
  width: 50%;
  /* background-color: orange; */
  text-align: left;
}
.conclude-data {
  width: 30%;
}
.line {
  width: 93%;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  margin: 2px auto;
}
.map-view {
  width: 100%;
  height: 100%;
  background-color: black;
}
