@font-face {
  font-family: "Ubuntu";
  src: url("../src/assets/font/ubuntu.ttf") format("truetype");
}
* {
  font-family: ubuntu, sans-serif;
  text-overflow: ellipsis;
  scrollbar-width: none;
  margin: 0;
  padding: 0;
}
body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
